import React, { FC, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';
import Tabs from 'components/Tabs/Tabs';
import { Event } from 'types/listings';

interface TabsInfo {
  labels: string[];
  contents: (string | string[])[];
}

const getTabsForEvent = (
  translate: (s: string) => string,
  event?: Event,
): TabsInfo => {
  const tabs: TabsInfo = {
    labels: [],
    contents: [],
  };

  if (event?.description) {
    tabs.labels.push(translate('about'));
    tabs.contents.push(event.description);
  }
  if (event?.agenda) {
    tabs.labels.push(translate('agenda'));
    tabs.contents.push(event.agenda);
  }
  if (event?.menu) {
    tabs.labels.push(translate('menu'));
    tabs.contents.push(event.menu);
  }
  if (
    event?.resourceGroups?.[0]?.included &&
    event?.resourceGroups?.[0]?.included.length > 0
  ) {
    tabs.labels.push(translate('included'));
    tabs.contents.push(event?.resourceGroups?.[0]?.included);
  }

  return tabs;
};

const EventDetails: FC = () => {
  const { isLoading, event } = useEventBookingSession();
  const { t: translate } = useTranslation();
  const tabs = useMemo(
    () => getTabsForEvent(translate, event),
    [event, translate],
  );
  const [showMore, setShowMore] = useState(false);

  if (isLoading || !event) {
    return (
      <Wrapper>
        <HeaderSkeleton />
        <TabsContentSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Tabs tabsLabel={tabs.labels} asBtn>
        {...tabs.contents.map((content) => {
          if (typeof content === 'string') {
            return (
              <TruncatedText
                mode={TruncationMode.INLINE}
                text={content}
                maxLines={showMore ? Infinity : 5}
                isRichText
                onShowMore={() => setShowMore(true)}
                showLessText={translate('viewLess')}
                showMoreText={translate('viewMore')}
                textBottomGap={8}
                customStyles={customTextStyles}
              />
            );
          }

          return (
            <IncludedItemsWrapper>
              {content.map((item) => (
                <IncludedItem key={item}>{item}</IncludedItem>
              ))}
            </IncludedItemsWrapper>
          );
        })}
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 36px 0px;
  border-bottom: solid 0.5px var(--way-colors-borderColor);
`;

const customTextStyles = css`
  & > p {
    font-size: 16px;
    line-height: 18px;
    color: var(--way-colors-secondaryTextColor);
  }
`;

const IncludedItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const IncludedItem = styled.span`
  user-select: none;
  padding: 8px 12px;
  border: 0.5px solid var(--way-colors-borderColor);
  border-radius: 0;
  font-weight: 900;
  color: var(--way-colors-contrastColorShades-60);
`;

const HeaderSkeleton = styled(SkeletonLine)`
  height: 24px;
  margin-bottom: 16px;
`;

const TabsContentSkeleton = styled(SkeletonLine)`
  height: 120px;
`;

export default EventDetails;
