import React, { FC, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useIsMobile } from 'WindowDimensionProvider';
import { ListingPictureKind } from '@kouto/types';
import useIsVisibleInPage from 'hooks/useIsVisibleInPage';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import MinimalVideoPlayer from 'components/MinimalVideoPlayer/MinimalVideoPlayer';
import { formatListingMedia, listingMediaIsVideo } from 'utils/listings';

const EventGallery: FC = () => {
  const isMobile = useIsMobile();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isVisibleInPage = useIsVisibleInPage(wrapperRef);
  const { isLoading, event } = useEventBookingSession();

  const eventGalleryMedias = useMemo(
    () =>
      formatListingMedia(
        (event?.medias || []).filter(
          (p) => p.kind !== ListingPictureKind.COVER,
        ),
      ),
    [event?.medias],
  );

  if (eventGalleryMedias.length === 0) {
    return null;
  }

  if (isLoading || !event) {
    return (
      <Wrapper ref={wrapperRef}>
        <GallerySkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={wrapperRef}>
      {eventGalleryMedias.map((galleryMedia, index) => {
        if (listingMediaIsVideo(galleryMedia)) {
          return (
            <VideoWrapper>
              <MinimalVideoPlayer
                play={isVisibleInPage}
                loop
                videoUrl={
                  isMobile ? galleryMedia.urlMobile : galleryMedia.urlDesktop
                }
                coverUrl={
                  isMobile
                    ? galleryMedia.previewMobile
                    : galleryMedia.previewDesktop
                }
              />
            </VideoWrapper>
          );
        }
        return (
          <img
            key={galleryMedia.id}
            src={isMobile ? galleryMedia.urlMobile : galleryMedia.urlDesktop}
            alt={`${event.title} gallery ${index + 1}`}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 85vh;
  overflow-x: scroll;
  padding: 42px 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5%;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  > * {
    scroll-snap-align: start;
    flex-shrink: 0;

    width: auto;
    max-width: 90%;
    object-fit: cover;
  }
  & > *:nth-child(1) {
    height: 100%;
  }
  & > *:nth-child(2) {
    height: 60%;
  }
  & > *:nth-child(3) {
    height: 80%;
  }
  & > *:nth-child(4) {
    height: 65%;
  }
  & > *:nth-child(5) {
    height: 100%;
  }
  & > *:nth-child(6) {
    height: 60%;
  }
  & > *:nth-child(7) {
    height: 80%;
  }
  & > *:nth-child(8) {
    height: 65%;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    height: 60vh;
  }
`;

const VideoWrapper = styled.div`
  min-width: 70vw;
`;

const GallerySkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventGallery;
